export class Constant {
  public static readonly CURRENT_USER = 'currentUser';

  public static readonly CODE_SUCCESS = 'SUCCESS';
  public static readonly CODE_FAIL = 'FAIL';
  public static readonly CODE_DUPLICATE = 'DUPLICATE';
  public static readonly INPUT_OLDPASSWORD_INVALID = 'INPUT_OLDPASSWORD_INVALID';
  public static readonly INPUT_INVALID = 'INPUT_INVALID';
  public static readonly NOTFOUND = 'NOTFOUND';
  
  public static readonly NOTI_SUCCESS = 'Thành công';
  public static readonly NOTI_SUCCESS_DETAIL = 'Thực hiện thành công!';

  public static readonly FIRST_PAGE = 1;
  public static readonly DEFAULT_SIZE = 10;

  public static readonly ENABLE = 0;
  public static readonly DISABLE = -1;

  public static readonly MAP_ROLES: Map<string, string> = new Map(
    [['ROLE_ADMIN','Admin'], ['ROLE_CLIENT','Khách hàng'],['ROLE_EMPLOYEE','Nhân viên']]);

}