import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constant } from '../shared/constants/constant.class';
import { URL } from '../shared/constants/url.class'

import { environment } from '../../environments/environment';
import { User } from '../models'; 

import jwt_decode from "jwt-decode";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(Constant.CURRENT_USER)));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get isAdmin() : boolean {
        var token = this.currentUserSubject.value.token;
        var decodedToken = jwt_decode(token);
        var roles = decodedToken['auth'];

        if (roles) {
            for(const role of roles) {
                if(role.authority == 'ROLE_ADMIN') {
                    return true;
                }
            }
        }
        return false;
    }

    login(phone: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}` + URL.LOGIN, { phone: phone, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(Constant.CURRENT_USER, JSON.stringify(user.data));
                this.currentUserSubject.next(user.data);
                return user;
            }));
    }

    register(phone: string, password: string, email: string) {
        return this.http.post<any>(`${environment.apiUrl}` + URL.REGISTER, { phone: phone, password, email })
            .pipe(map(res => {
                return res;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(Constant.CURRENT_USER);
        this.currentUserSubject.next(null);
    }

    resetPassword(email: string) {
        return this.http.put<any>(`${environment.apiUrl}` + 'auth/reset-password', { email: email })
            .pipe(map(res => {
                return res;
            }));
    }
}