export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;

    email: string;
    phone: string;
    address: string;
    city: string;
    roles: any[];
    updatedAt: any;
    createdAt: any;
    status:number;
    notifyStatus: number;
}