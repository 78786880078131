import { Component } from '@angular/core';

@Component({
  templateUrl: '401.component.html'
})
export class P401Component {

  constructor() { }

}
