import { Injectable } from '@angular/core';

import { BaseService } from '../shared/base-service/base-service.service';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
    private static readonly URL_USERS = 'users';
    private static readonly URL_REGISTER = '/register';
    getAll() {
        return this.get(UserService.URL_USERS);
    }

    getById(id: number) {
        return this.get(UserService.URL_USERS + '/' + id);
    }

    deleteById(id: number) {
        return this.delete(UserService.URL_USERS, id);
    }

    save(data: any) {
        if (data.id) {
            return this.put(UserService.URL_USERS, data);
        } else {
            return this.post(UserService.URL_USERS, data);
        }
    }
    register(data: any) {
        return this.post(UserService.URL_USERS + UserService.URL_REGISTER, data);
    }

    updateNotifyStatus(notifyStatus: number){
        return this.put(UserService.URL_USERS + '/update-notify-status', notifyStatus)
    }
}