import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user';
import { AuthenticationService, UserService } from '../../services';
import { Constant } from '../../shared/constants/constant.class';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit{
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  success = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, 
    private userService: UserService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.registerForm = this.formBuilder.group({
      phone: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      email: [null, [Validators.required, Validators.email, Validators.maxLength(255)]],
      password: [null, Validators.required],
      rePassword: [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.error = this.success = '';
    this.submitted = true;
    this.loading = true;

    if (this.registerForm.valid) {

      //check password and rePassword
      if(this.registerForm.value.password !== this.registerForm.value.rePassword) {
        this.error = 'Xác nhận mật khẩu không khớp!';
        this.loading = false;
        return;
      }
      
      this.submitted = false;
      var user = new User();
      user.phone = this.f.phone.value;
      user.email = this.f.email.value;
      user.password = this.f.password.value;

      this.authenticationService.register(this.f.phone.value, this.f.password.value, this.f.email.value)
        .pipe(first())
        .subscribe(
          res => {
            if (res != null && res.code == Constant.CODE_DUPLICATE) {
              this.error = 'Thông tin tài khoản đã tồn tại!';
            } else if (res != null && res.code == Constant.CODE_FAIL) {
              this.error = 'Đăng ký tài khoản thất bại!';
            } else {
              this.success = 'Bạn đã đăng ký tài khoản thành công ! Vui lòng chờ admin kích hoạt !';
            }
            this.loading = false;
          },
          error => {
            this.error = error;
            this.loading = false;
          });
    } else {
      this.loading = false;
    }
  }

}
