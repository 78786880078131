import { Injectable } from '@angular/core';

import { BaseService } from '../shared/base-service/base-service.service';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {
    private static readonly URL_NOTI = 'notifications';
    
    getByIdAndPhone(phone: string, size: number, page: number) {
        return this.get(NotificationService.URL_NOTI, {phone: phone, size: size, page: page});
    }

    seen(id: number) {
        return this.put(NotificationService.URL_NOTI, null, {id: id});
    }
}