import {Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { User } from '../../models/user'
import { Constant } from '../../shared/constants/constant.class';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{
  public sidebarMinimized = false;
  public navItems = navItems;
  
  currentUser: User;
  isLogged: boolean = false;
  notifications: Notification[] = [];
  countNotiUnread: number;
  isDisplayedAllNotification = false;

  size = 14;
  page = 0;

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private notificationService: NotificationService,
  ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      if (this.currentUser) {
        this.isLogged = true;
      }
  }

  ngOnInit(){
   this.notificationService.getByIdAndPhone(this.currentUser.phone, this.size, this.page).subscribe(res => {
     this.notifications = res.listData;
     this.countNotiUnread = res.other;
   });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.authenticationService.logout();
    this.isLogged = false;
    this.router.navigate(['/login']);
  }

  viewNotification(n: any) {
    //update status notification to seen
    if (n.status === Constant.ENABLE) {
      n.status = Constant.DISABLE;
      this.countNotiUnread--;
      this.notificationService.seen(n.id).subscribe(res => {
      });
    }
    
    // redirect to page order detail
    this.router.navigate(['/cau-hinh/don-hang/' + n.link]);
  }

  viewMore(){
    this.page++;
    this.notificationService.getByIdAndPhone(this.currentUser.phone, this.size, this.page).subscribe(res => {
      if(res?.code == Constant.CODE_SUCCESS && res?.listData?.length > 0) {
        this.notifications = this.notifications.concat(res.listData);
        this.countNotiUnread = res.other;
      }
    });
    console.log(this.notifications);
  }

  onScroll(event) {
    if(!this.isDisplayedAllNotification) {
      this.page++;
      this.notificationService.getByIdAndPhone(this.currentUser.phone, this.size, this.page).subscribe(res => {
        if(res?.code == Constant.CODE_SUCCESS && res?.listData?.length > 0) {
          this.notifications = this.notifications.concat(res.listData);
          this.countNotiUnread = res.other;
        } else if(res?.listData?.length == 0) {
          this.isDisplayedAllNotification = true;
        }
      });
    }
  }
}
