import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Constant } from '../../shared/constants/constant.class';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loadingResetPassword = false;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  success = '';
  isForgetPassword = false;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService
  ) { 
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
          this.router.navigate(['/']);
      }
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required],
          email: null,
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
            data => {
                if (data != null && data.code == Constant.CODE_SUCCESS) {
                    this.router.navigate([this.returnUrl]);
                } else {
                    this.error = Constant.CODE_FAIL;
                }
                this.loading = false;
            },
            error => {
                this.error = error;
                this.loading = false;
            });
  }

  resetPassword() {
    this.error = '';
    this.success = '';
    this.loadingResetPassword = true;
    this.authenticationService.resetPassword(this.f.email.value)
    .pipe(first()).subscribe(
        data => {
            this.loadingResetPassword = false;
            if (data != null && data.code == Constant.CODE_SUCCESS) {
                this.success = 'Mật khẩu mới đã được gửi vào email!';
            } else {
                this.error = data.message;
            }
        }, error => {
            this.error = 'Có lỗi xảy ra, vui lòng thử lại sau!';
            this.loadingResetPassword = false;
        }
    );
  }
}

