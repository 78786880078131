import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import { Constant } from '../constants/constant.class';

@Injectable()
export class BaseService {

  constructor(
    public httpClient: HttpClient
  ) {
  }
  get(url: string, params?: {}, responseType?: string): Observable<any> {
    switch (responseType) {
      case 'text':
        return this.httpClient.get(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          params,
          responseType: 'text',
        });
      case 'blob':
        return this.httpClient.get(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          params,
          responseType: 'blob',
        });
      default:
        var url = `${environment.apiUrl}` + url;
        return this.httpClient.get(url, {
          headers: this.createHeaders(),params
        });
    }
  }
  async getWithAsync(url: string, params?: {}, responseType?: string) {
    switch (responseType) {
      case 'text':
        return await this.httpClient.get(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          params,
          responseType: 'text',
        }).toPromise();
      case 'blob':
        return await this.httpClient.get(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          params,
          responseType: 'blob',
        }).toPromise();
      case 'arraybuffer':
        return await this.httpClient.post(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          responseType: 'arraybuffer',
          params
        }).toPromise();
      default:
        return await this.httpClient.get(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          params
        }).toPromise();
    }
  }
  /**
   * Create a new entity.
   * @param url the api url
   * @param data the entity to create
   */
  post(url: string, data: any, params?: {}, responseType?: string, partnerUrl?: boolean): Observable<any> {
    switch (responseType) {
      case 'text':
        return this.httpClient.post(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
          responseType: 'text',
          params
        });
      case 'blob':
          return this.httpClient.post(`${environment.apiUrl}` + url, data, {
            headers: this.createHeaders() || {},
            responseType: 'blob',
            params
        });
      default:
        return this.httpClient.post(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
          params
        });
    }
  }

  async postWithAsync(url: string, data: any, params?: {}, responseType?: string): Promise<any> {
    switch (responseType) {
      case 'text':
        return await this.httpClient.post(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
          responseType: 'text',
          params
        }).toPromise();
      case 'blob':
        return await this.httpClient.post(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
          responseType: 'blob',
          params
        }).toPromise();
      default:
        return await this.httpClient.post(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
          params
        }).toPromise();
    }
  }

  /**
   * Update an entity.
   * @param url the api url
   * @param data the entity to be updated
   */
  put(url: string, data: any, params?: {}, responseType?: string): Observable<any> {
    switch (responseType) {
      case 'text':
        return this.httpClient.put(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
         responseType: 'text',
         params
        });
      default:
        return this.httpClient.put(`${environment.apiUrl}` + url, data, {
          headers: this.createHeaders() || {},
          params
        });
    }
  }

  delete(url: string, id: any, responseType?: string): Observable<any> {
    switch (responseType) {
      case 'text':
        return this.httpClient.delete(`${environment.apiUrl}` + url + "?id=" + id, {
          headers: this.createHeaders() || {},
          responseType: 'text'
        });
      default:
        return this.httpClient.delete(`${environment.apiUrl}` + url + "?id=" + id, {
          headers: this.createHeaders() || {},
        });
    }
  }

  deleteByParam(url: string,params?: {}, responseType?: string): Observable<any> {
    switch (responseType) {
      case 'text':
        return this.httpClient.delete(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          responseType: 'text',
          params
        });
      default:
        return this.httpClient.delete(`${environment.apiUrl}` + url, {
          headers: this.createHeaders() || {},
          params
        });
    }
  }

  public createHeaders() {
    // Why "authorization": see CustomLogoutSuccessHandler on server
    return new HttpHeaders()
      .set('authorization', 'Bearer ' + this.getToken())
  }
  private getToken() {
    return JSON.parse(localStorage.getItem(Constant.CURRENT_USER))?.token;
  }
}
