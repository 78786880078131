import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Hệ thống'
  },
  {
    name: 'Danh mục',
    url: '/cau-hinh/danh-muc',
    icon: 'icon-drop'
  },
  {
    name: 'Khuyến mại',
    url: '/cau-hinh/khuyen-mai',
    icon: 'icon-drop'
  },
  {
    name: 'Bài viết',
    url: '/cau-hinh/bai-viet',
    icon: 'icon-drop'
  },
  {
    name: 'Đơn hàng',
    url: '/cau-hinh/don-hang',
    icon: 'icon-drop'
  },
  {
    name: 'Sản phẩm',
    url: '/cau-hinh/san-pham',
    icon: 'icon-drop'
  },
  {
    title: true,
    name: 'Tài khoản'
  },
  {
    name: 'Người dùng',
    url: '/tai-khoan/nguoi-dung',
    icon: 'icon-drop'
  }

];
